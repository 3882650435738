import auth from "../middlewares/authMiddleware";
import ShowPickups from "@/components/pages/Pickups/ShowPickups.vue";

export default [
  {
    path: '/pickups',
    name: 'pickups.list',
    component: ShowPickups,
    meta: {
      middleware: [auth],
      title: 'Liste des Enlèvements',
      permissions: ['ORDER_READ']
    }
  },

];