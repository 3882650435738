<template>
  <status-indicator
    :small="small"
    :type="getType"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "PickupStatusBadge",
  components: {StatusIndicator},
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getText() {
      return this.getConfigValues?.label || this.status;
    },
    getType() {
      return this.getConfigValues?.statusColor || this.type;
    },
    getConfigValues() {
      return this.$luxcaddyConfig('pickup.availableStatuses').find(l => l.value === this.status);
    }
  },
}
</script>

<style scoped>

</style>