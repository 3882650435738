<template>
  <div>
    <touch-optimized-button @click="openModal">
            <span v-if="getSelectedDay && !loading" class="font-bold">
                {{ $date(getSelectedDay).format('DD.MM.YYYY') }}
            </span>
      <fa-icon v-else class="animate-spin transition" icon="circle-notch"></fa-icon>
    </touch-optimized-button>

    <touch-optimized-modal ref="modal">
      <div class="flex flex-wrap gap-8 clear-both">
        <touch-optimized-modal-button
          v-for="date in availableWeekDays"
          :key="date.day"
          @click="onClickDate(date)"
        >
          {{ $date(date.date).format('DD.MM.YYYY') }}
          <div class="text-center text-luxcaddy-300 mt-6">
            {{ date.orders }}
          </div>
        </touch-optimized-modal-button>
      </div>
    </touch-optimized-modal>
  </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import OrderRepository from "@/repositories/OrderRepository";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "OrderFulfilmentListDateButton",
  components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
  data: () => ({
    availableWeekDays: [],
    loading: false
  }),
  mounted() {
    const today = this.$date();

    // Set Date to today if not set or in the past.
    if (this.getSelectedDay === null || this.getSelectedDay === undefined || this.$date(this.getSelectedDay) .isBefore(today)) {
      this.setSelectedDay(today.format('YYYY-MM-DD'));
    }
  },
  methods: {
    ...mapMutations('fulfilmentFilters', [
      'setSelectedDay',
      'setPickupFilter',


    ]),

    onClickDate(date) {
      this.setSelectedDay(date.date);
      this.$refs.modal.close();
    },
    openModal() {
      this.fetchDays().then(() => {
        this.$refs.modal.open();
      });
    },
    fetchDays() {
      this.loading = true;
      return OrderRepository.fulfilment.getNextWeekdaysWithOrderCount(this.getPickupFilterValue).then((res) => {
        this.availableWeekDays = res.data.data;
      }).finally(() => this.loading = false);
    }
  },
  computed: {
    ...mapGetters('fulfilmentFilters', [
      'getSelectedDay',
      'getPickupFilterValue'
    ])
  },
}
</script>

