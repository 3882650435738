<template>
  <svg class="h-24 w-24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" version="1.1"  viewBox="0 0 524.541 524.538">
    <defs>
      <clipPath id="clip_0">
        <path transform="matrix(1,0,0,-1,0,524.538)" d="M0 524.538H524.541V0H0Z"/>
      </clipPath>
    </defs>
    <g inkscape:groupmode="layer" inkscape:label="Layer 1">
      <g clip-path="url(#clip_0)">
        <path transform="matrix(1,0,0,-1,262.2701,455.4215)" d="M0 0C106.688 0 193.175 86.478 193.175 193.152 193.175 299.828 106.688 386.305 0 386.305-106.687 386.305-193.174 299.828-193.174 193.152-193.174 86.478-106.687 0 0 0" fill="#ffffff"/>
        <path transform="matrix(1,0,0,-1,262.2701,437.3228)" d="M0 0C96.68 0 175.054 78.374 175.054 175.054 175.054 271.733 96.68 350.107 0 350.107-96.679 350.107-175.054 271.733-175.054 175.054-175.054 78.374-96.679 0 0 0" fill="#00c2e8"/>
        <path transform="matrix(1,0,0,-1,233.0303,217.02213)" d="M0 0C-2.839 .026-5.36-.094-6.61-.214-9.099-.448-9.959-2.801-9.8-5.127-7.471-38.801-15.219-77.902-23.65-77.829-29.923-77.769-31.518-59.455-31.372-43.173-31.292-33.548-30.664-24.283-30.36-16.096-30.253-13.055-31.352-12.473-33.542-12.192-36.864-11.758-39.246-11.737-42.555-12.439-45.083-12.98-45.698-15.046-48.405-21.329-61.897-52.644-74.867-77.782-74.867-77.782-78.017-52.443-73.477-27.933-69.639-10.053-69.11-7.586-70.274-6.123-72.299-6.137-76.118-6.169-81.974-6.243-84.634-7.947-87.128-9.552-88.062-11.276-88.81-14.852-93.077-35.339-95.962-61.052-92.019-80.99-91.165-85.302-90.179-86.324-88.373-87.468-86.566-88.61-78.778-89.687-75.754-89.713-72.319-89.747-69.169-88.851-66.516-84.387-60.898-74.941-56.378-65.504-47.842-47.31-47.187-71.111-43.991-89.82-27.276-89.967-5.115-90.161 9.668-34.924 9.893-8.288 9.959-1.243 6.472-.061 0 0" fill="#ffffff"/>
        <path transform="matrix(1,0,0,-1,384.1265,247.69492)" d="M0 0C0 0-7.484 .461-16.1 .915-16.1 .915-14.273 10.467-13.387 14.363-12.949 16.269-13.942 18.174-16.351 18.782-18.739 19.384-24.245 19.47-26.098 19.276-27.64 19.116-29.148 18.207-29.506 16.609-30.412 12.619-31.358 7.813-32.49 1.657-35.687 1.764-38.796 1.817-40.953 1.798-42.965 1.777-43.925 .762-44.348-.896-44.778-2.547-45.136-4.906-45.295-7.353-45.413-9.204-43.396-10.106-41.311-10.047-41.311-10.047-38.757-9.953-34.747-9.793-35.521-13.295-37.082-22.68-37.698-28.521-41.417-40.64-49-49.542-55.465-49.482-59.422-49.449-61.765-47.076-61.705-39.951-61.619-30.079-59.243-17.619-55.829-5.141-51.071 6.042-48.279 18.494-48.232 24.149-48.213 26.274-48.842 28.66-51.382 29.188-55.975 30.145-60.349 30.011-63.419 29.089-66.165 28.26-66.747 25.386-67.092 23.854-68.739 16.489-71.438 3.436-73.352-8.015-77.414-12.854-82.073-16.235-87.082-17.031-87.73-1.845-96.505 4.752-108.674 4.859-130.041 5.053-143.825-13.869-144.01-35.238-144.162-52.55-134.779-59.85-121.028-59.977-104.796-60.117-92.614-48.7-87.922-27.612-83.316-26.717-79.313-24.518-75.846-21.449-75.846-21.449-78.546-34.517-78.612-42.203-78.718-53.854-73.769-60.391-62.029-60.491-52.368-60.577-43.879-53.84-38.538-45.605-37.215-56.434-31.604-60.765-22.426-60.846-13.995-60.919-5.829-55.645-.007-46.02 1.158-44.102 2.005-42.417 1.291-41.575-.363-39.644-3.533-38.621-4.42-40.205-8.271-47.103-12.36-49.863-16.794-49.823-20.302-49.797-23.12-47.685-23.067-41.602-22.994-32.846-17.859-9.225-17.859-9.225-13.525-9.104-8.708-8.997-4.129-8.938-.112-8.877 2.739-7.68 3.468-3.87 3.897-1.604 2.581-.141 0 0M-118.521-50.585C-123.437-50.545-126.686-46.273-126.613-37.865-126.474-21.737-120.585-4.438-110.275-4.532-109.117-4.539-108.092-4.706-107.185-5.073-107.351-5.695-107.482-6.21-107.536-6.497-109.157-15.635-107.324-21.477-102.983-24.738-105.339-38.881-111.704-50.645-118.521-50.585" fill="#ffffff"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "WoltLogo"
}
</script>