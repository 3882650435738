<template>
  <div>
    <list-table
      :columns="columns"
      :filters="filters"
      :pagination="true"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="pickupsTable"
    >
      <template v-slot:type-template="{row}">
        <wolt-logo  v-if="row.type === 'wolt'" />
        <div v-else>{{ row.type.toUpperCase() }}</div>
      </template>

      <template v-slot:status-template="{row}">
        <pickup-status-badge small :status="row.status" />
      </template>

      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal :id="row.memberId"/>
      </template>
      <template v-slot:order-id-template="{ row }">
        <order-id-link :order-id="row.orderId"/>
      </template>

    </list-table>
  </div>
</template>

<script>
import ListTable from "../../listTable/ListTable";
import MemberQuickActionModal from "../Members/Subcomponents/MemberQuickActionModal";
import PickupRepository from "@/repositories/PickupRepository";
import OrderIdLink from "@/components/pages/Orders/OrderIdLink.vue";
import WoltLogo from "@/components/assets/Pickup/ProviderLogos/WoltLogo.vue";
import PickupStatusBadge from "@/components/pages/Pickups/PickupStatus/PickupStatusBadge.vue";
import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "ShowPickups",
  components: {PickupStatusBadge, WoltLogo, OrderIdLink, MemberQuickActionModal, ListTable},
  data() {
    return {
      title: 'Enlèvements',
      columns: [
        {
          caption: 'Commande',
          width: 80,
          cellTemplate: "order-id-template",
        },
        {
          caption: 'Status',
          cellTemplate: "status-template",
        },
        {
          caption: 'Nom Client',
          dataField: 'consumerName',
          width: 150
        },
        {
          caption: 'Adresse',
          dataField: 'address',
          visible: false,
          calculateCellValue: r => {
            if(r.address === null) {
              return '/';
            }

            return r.address.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }
        },
        {
          caption: 'Tél.',
          dataField: 'consumerName',
          calculateCellValue: r => {
            if(r.phonePrefix === null && r.phone === null) {
              return '/';
            }

            return r.phonePrefix + ' ' + r.phone;
          }
        },
        {
          caption: 'Commentaire',
          dataField: 'comment',
          visible: false,
        },
        {
          caption: 'Enlèvement à environ',
          calculateCellValue: (r) => {
            return this.$date(r.pickupEta).format('DD.MM.YYYY HH:mm');
          },
          width: 160,
          sort: {
            dataField: 'pickupEta',
            default: 'desc'
          }
        },
        {
          caption: 'Enlèvée le',
          calculateCellValue: (r) => {
            if(r.pickedUpAt === null) {
              return '/';
            }

            return this.$date(r.pickedUpAt).format('DD.MM.YYYY HH:mm');
          },
          sort: {
            dataField: 'pickedUpAt'
          }
        },
        {
          caption: 'Service',
          width: 80,
          cellTemplate: "type-template",
          visible: false
        },
      ],
      filters: [
        {
          caption: "ID Commande",
          filterKey: "orderId",
          forceIsVisible: true,
        },
        {
          caption: "Enlèvée entre",
          filterKey: "pickedUpAt",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true
        },
        {
          caption: "Enlèvement (ETA) entre",
          filterKey: "pickupEta",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true
        },
      ],
      repositoryFunc: PickupRepository.getPaginated
    }
  }
}
</script>


