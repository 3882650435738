<template>
  <div>
    <list-table
      :key="getTableKey"
      :batch-actions="OrderBatchActions.batchActions"
      :columns="columns"
      :create-button="false"
      :create-route="{
                name: 'orders.create'
            }"
      :filters="filters"
      :pagination="true"

      :repository-func="repositoryFunc"
      :select-values="OrderBatchActions.selectValues"
      :title="title"

      enable-totals
      identifier="ordersTable"
      selectable
      show-filters-immediately
    >
      <template v-slot:member-quick-action-template="{ row }">
        <member-quick-action-modal
          :id="row.memberId"
        />
      </template>

      <template v-slot:invoice-id-template="{ row }">
        <text-link
          v-if="row.invoiceId"
          :to="{name: 'invoices.edit', params: {id: row.invoiceId}}"
        >
          #{{ row.invoiceId }}
        </text-link>
        <div v-else>
          /
        </div>
      </template>

      <template v-slot:delivery-id-template="{ row }">
        <text-link
          v-if="row.delivery"
          :to="{name: 'deliveries.edit', params: {deliveryId: row.delivery.id}}"
        >
          #{{ row.delivery.id }}
        </text-link>
        <div v-else>
          /
        </div>
      </template>

      <template v-slot:timeslot="{ row }">
        <time-slot-as-string
          :from="row.delivery?.timeSlot?.startDate"
          :to="row.delivery?.timeSlot?.endDate"
          :zone="row.delivery?.timeSlot?.zoneId"/>
      </template>

      <template v-slot:order-status-template="{ row }">
        <order-status-indicator
          :status="row.status"
          small
        />
      </template>

      <template v-slot:fulfilment-status-template="{ row }">
        <order-fulfilment-status-indicator
          :fulfilment-status="row.fulfilmentStatus"
          small
        />
      </template>

      <template v-slot:fulfilment-progress-template="{ row }">
        <fulfilment-progress
          :percentage="row.fulfilmentProgressPercentage"
          class="w-24"
        />
      </template>

      <template v-slot:fulfilment-number-template="{ row }">
        <fulfilment-number-display
          :fulfilment-number="row.delivery?.fulfilmentNumber"
          :has-delivery="row.delivery !== null"

        />

      </template>

    </list-table>
  </div>
</template>

<script>
import OrderRepository from "@/repositories/OrderRepository";
import ListTable from "@/components/listTable/ListTable";
import {
    FILTER_BOOLEAN,
    FILTER_CURRENCY,
    FILTER_DATETIME_RANGE, FILTER_MEMBER_EMPLOYEES_MULTI,
    FILTER_PRODUCT_MULTI,
    FILTER_SELECT_MULTI,
    FILTER_TEXT
} from "../../listTable/includes/controls/filters/availableFilters";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import OrderStatusIndicator from "@/components/pages/Products/PackagingInfo/Status/OrderStatusIndicator";
import {centsToEuroString} from "@/helpers/mathHelper";
import TimeSlotAsString from "../../global/TimeSlots/TimeSlotAsString";
import OrderFulfilmentStatusIndicator from "@/components/pages/Orders/EditOrder/Info/OrderFulfilmentStatusIndicator";
import TextLink from "@/components/global/TextLink";
import FulfilmentProgress from "@/components/pages/TouchInterface/Packaging/Fulfilment/Includes/FulfilmentProgress.vue";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay.vue";
import OrderBatchActions from "@/batchAction/orderBatchActions";

export default {
  name: "ShowOrders",
  components: {
    FulfilmentNumberDisplay,
    FulfilmentProgress,
    TextLink,
    OrderFulfilmentStatusIndicator,
    TimeSlotAsString, OrderStatusIndicator, MemberQuickActionModal, ListTable
  },
  computed: {
    OrderBatchActions() {
      return OrderBatchActions
    },
    // used as a workaround to render the table again when switching from a shortcut (today's orders, tomorrows order, ...) to the regular page,
    getTableKey() {
      return this.$route.name + '_' + Math.random();
    },
    hasDelivery(row) {
      return row.order?.delivery !== null;
    }
  },
  data() {
    return {
      title: 'Commandes',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir commande',
              route: (row) => {
                return {
                  name: 'orders.edit',
                  params: {
                    order: row.id
                  },
                  query: {
                    readonly: true
                  }
                };
              }
            },
            {
              type: 'edit',
              hint: 'Modifier commande',
              route: (row) => {
                return {
                  name: 'orders.edit',
                  params: {
                    order: row.id
                  }
                };
              }
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          dataType: 'numeric',
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Mem',
          width: 80,
          cellTemplate: 'member-quick-action-template',
          visible: true
        },
        {
          caption: 'Fac',
          width: 80,
          cellTemplate: 'invoice-id-template',
          visible: true
        },
        {
          caption: 'Liv',
          width: 80,
          cellTemplate: 'delivery-id-template',
          visible: true
        },
        {
            caption: 'Abonn.',
            dataField: 'subscriptionId',
            visible: false,
            width: 80,
            sort: {
                dataField: 'subscriptionId',
            }
        },
        {
          caption: 'Nom Client',
          dataField: 'member.invoicingDetails.fullName',
          sort: {
            dataField: 'customerName'
          }
        },
        {
          caption: 'Ville',
          dataField: 'member.invoicingDetails.city',
          sort: {
            dataField: 'city'
          },
          visible: true
        },
        {
          caption: 'Total TTC',
          dataType: 'euro',
          dataField: 'total.amount',
          totals: {
            sum: "total.amount",
            formatValue: (val) => centsToEuroString(val)
          },
          visible: true
        },
        {
          caption: 'Status',
          cellTemplate: 'order-status-template',
          visible: true
        },
        {
          caption: 'Préparation',
          cellTemplate: 'fulfilment-status-template',
          visible: true
        },
        {
          caption: '% Préparation',
          cellTemplate: 'fulfilment-progress-template',
          visible: true
        },
        {
          caption: 'BAC',
          cellTemplate: 'fulfilment-number-template',
          visible: true
        },
        {
          caption: 'Date com.',
          calculateCellValue: (r) => this.$date(r.createdAt).format('DD.MM.YY HH:mm'),
          sort: {
            dataField: 'createdAt',
          },
          visible: true
        },
        {
          caption: 'Créneau de livraison',
          cellTemplate: 'timeslot',
          visible: true
        },
        {
            caption: 'Livreur',
            dataField: "driver",
            visible: false
        },
      ],
      filters: [
        {
          caption: "ID(s)",
          filterKey: "orderIds",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "ID(s) ou Nom Client",
          filterKey: "memberIdOrName",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "ID Facture",
          filterKey: "invoiceid",
          forceIsVisible: true,
          small: true,
        },
        {
          caption: "Facture No.",
          filterKey: "invoiceNumber",
        },
        {
          caption: "Email client",
          filterKey: "memberEmail",
          small: true,
        },
        {
          caption: "Adresse",
          filterKey: "customerAddress",
          small: true
        },
        {
          caption: "Status",
          filterKey: "status",
          filterOptions: this.$luxcaddyConfig('order.availableStatuses'),
          type: FILTER_SELECT_MULTI,
          small: true,
        },
        {
          caption: "Status de préparation",
          filterKey: "fulfillmentStatus",
          filterOptions: this.$luxcaddyConfig('order.availableFulfillmentStatuses'),
          type: FILTER_SELECT_MULTI,
          forceIsVisible: true,
          small: true
        },
        {
          filterKey: 'amount',
          inputs: [
            {
              caption: 'Montant de',
              inputKey: 'amount_from',
              type: FILTER_CURRENCY
            },
            {
              caption: 'Montant à',
              inputKey: 'amount_to',
              type: FILTER_CURRENCY
            }
          ]
        },
        {
          caption: "Livraison entre",
          filterKey: "timeSlot",
          type: FILTER_DATETIME_RANGE,
          forceIsVisible: true
        },
        {
          caption: "Crée entre",
          filterKey: "createdAt",
          type: FILTER_DATETIME_RANGE,
        },
        {
          caption: "Produits",
          filterKey: "productIds",
          type: FILTER_PRODUCT_MULTI
        },
        {
          caption: "N°",
          filterKey: "fulfilmentNumber",
          type: FILTER_TEXT,
          forceIsVisible: true,
          small: true
        },
        {
            caption: "Livreur",
            filterKey: "driver",
            type: FILTER_MEMBER_EMPLOYEES_MULTI,
            small: true
        },
        {
          caption: 'Avec livraison',
          filterKey: 'withDelivery',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: 'Avec abbon.',
          filterKey: 'isFromSubscription',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
            caption: "Abonn.",
            filterKey: "subscriptionId",
            forceIsVisible: false,
            small: true
        },
        {
          caption: 'Avec Supplément',
          filterKey: 'usedBuyingPriceWithAddons',
          type: FILTER_BOOLEAN,
          small: true
        },
        {
          caption: "Par ID parent",
          filterKey: 'relatedOrdersByParentId',
          type: FILTER_TEXT,
          small: true
        },
        {
          caption: "A des comm. liées",
          filterKey: 'hasChildOrders',
          type: FILTER_BOOLEAN,
        },
        {
          caption: "Avec pickup?",
          filterKey: 'hasPickup',
          type: FILTER_BOOLEAN,
        },
        {
            caption: "Pickup entre",
            filterKey: "pickupTimeSLot",
            type: FILTER_DATETIME_RANGE,
        }
      ],
      repositoryFunc: OrderRepository.getPaginated
    }
  },
}
</script>


