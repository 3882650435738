// noinspection DuplicatedCode

import ApiService from './Base/BaseRepository';

const url = '/wolt';


export default {
  venues: {
    async getVenueStatus() {
      return await ApiService
        .get(`${url}/venue/status`);
    },

    async setVenueOnline (onlineStatus, until) {
      return await ApiService
        .post(`${url}/venue/online`, {
          "status": onlineStatus,
          until: until
        });
    }
  }

}
