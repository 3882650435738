<template>
  <div>
    <div>
      <div class="flex gap-x-4 items-center">
        <strong>Status actuel:</strong>
        <div class="w-24">
          <badge :type="getStatusBadgeColor" class="w-fill pl-4">{{ getStatusText }}</badge>
        </div>
      </div>


      <div class="mt-6 | flex gap-x-3 ">
        <template v-if="isOnline">
          <button-element inverse type="danger" @click="closeUntilDate">Fermer jusqu'à une certaine heure</button-element>
          <button-element type="danger" @click="closeNow">Fermer</button-element>

          <wolt-close-venue-until-time-modal
            @change="getVenueStatus"
            ref="closeUntilDateModal"
          />

          <delete-confirmation-modal
            @delete="setWoltOffOrOnline('OFFLINE')"
            title="Fermer Wolt"
            delete-button-icon="fa-check"
            delete-button-label="Oui, Fermer"
            ref="confirmCloseNowModal"
          />
        </template>

        <template v-else>
          <button-element type="primary" @click="openNow">Ouvrir</button-element>

          <delete-confirmation-modal
            @delete="setWoltOffOrOnline('ONLINE')"
            title="Ouvrir Wolt"
            delete-button-icon="fa-check"
            delete-button-label="Oui, Ouvrir"
            delete-button-type="primary"
            ref="confirmOpenNowModal"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import WoltRepository from "@/repositories/WoltRepository";
import Badge from "@/components/elements/badges/Badge.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import WoltCloseVenueUntilTimeModal
  from "@/components/pages/Wolt/ManageOnlineStatus/Modal/WoltCloseVenueUntilTimeModal.vue";
import DeleteConfirmationModal from "@/components/elements/modals/DeleteConfirmationModal.vue";

export default {
  name: "WoltManageVenueOnlineStatusForm",
  components: {DeleteConfirmationModal, WoltCloseVenueUntilTimeModal, ButtonElement, Badge},
  data() {
    return {
      isOnline: false
    }
  },
  created() {
    this.getVenueStatus();
  },
  methods: {
    getVenueStatus() {
      WoltRepository.venues.getVenueStatus().then(response => {
        this.isOnline = response.data.data.is_online;
      });
    },
    closeUntilDate() {
      this.$refs.closeUntilDateModal.openModal();
    },
    closeNow() {
      this.$refs.confirmCloseNowModal.openModal();
    },
    openNow() {
      this.$refs.confirmOpenNowModal.openModal();
    },
    setWoltOffOrOnline(onlineStatus) {
      if(onlineStatus !== "ONLINE" && onlineStatus !== "OFFLINE") {
        throw new Error("Invalid onlineStatus has been passed.");
      }

      WoltRepository.venues.setVenueOnline(onlineStatus, null)
        .then(() => {
          const messageType = onlineStatus === "ONLINE" ? "ouvert" : "fermée";
          this.$sendSuccessToast(`Notre boutique sur Wolt est désormais ${messageType}.`);
        })
        .finally(() => {
        this.getVenueStatus();
      });
    },
  },
  computed: {
    getStatusText() {
      return this.isOnline ? "Ouvert" : "Fermé";
    },
    getStatusBadgeColor() {
      return this.isOnline ? "primary" : "danger";
    },
  },
}
</script>