import apiService from './Base/BaseRepository';

const url = '/pickups';

export default {
  /**
   * Fetches all pickups.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0, showLoadingIndicator = true,) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}`, {
        showLoadingIndicator
      });
  },
}
