import auth from "@/router/middlewares/authMiddleware";
import WoltUpdateVenueOnlinePage from "@/components/pages/Wolt/WoltUpdateVenueOnlinePage.vue";

export default [
    {
        component: WoltUpdateVenueOnlinePage,
        meta: {
            middleware: [auth],
            permissions: ['TIMESLOT_WRITE'], //todo maybe change
            title: '[Wolt] Définir l\'ouverture',
        },
        path: '/wolt/update-venue-online',
        name: 'wolt.venue-online.update',
    },
]