<template>
  <div class="flex flex-col gap-y-3">
    <div class="flex items-center flex-wrap gap-x-3 gap-y-4 mb-2">
      <delivery-status-indicator
        v-if="getOrder"
        :status="getOrder.delivery.status"
        small
      />

      <box-quantity :quantity="getOrder.delivery.boxQuantity"/>
      <fulfilment-number-display
        :fulfilment-number="getOrder.delivery && getOrder.delivery.fulfilmentNumber"/>
      <time-slot-as-string
        v-if="getDeliveryAddress"
        :from="$date(getDeliveryTimeSlot.startDate)"
        :to="$date(getDeliveryTimeSlot.endDate)"
        :zone="getDeliveryTimeSlot.zoneId"
      />
    </div>

    <address-display :address="getDeliveryAddress" :show-map-button="true"/>


    <div v-if="getDeliveryInstructions" class="flex items-center gap-4 break-words">
      <fa-icon class="text-gray-400" icon="quote-right"></fa-icon>
      <span class="text-gray-400 text-sm">
                            {{ getDeliveryInstructions }}
                        </span>
    </div>

    <div class="flex items-center gap-3 mt-1">
      <button-element
        :disabled="getOrder.delivery === null"
        class="flex-start"
        size="xsmall"
        type="light"
        @click="$router.push({name: 'deliveries.edit', params: {deliveryId: getOrder.delivery.id}})"
      >
        <fa-icon class="mr-2" fixed-width icon="truck"/>
        Voir Livraison
      </button-element>

      <!--              <edit-order-delivery-details/>-->
    </div>
  </div>
</template>

<script>
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity.vue";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import AddressDisplay from "@/components/elements/display/address/AddressDisplay.vue";
import DeliveryStatusIndicator from "@/components/elements/display/deliveries/DeliveryStatusIndicator.vue";
import TimeSlotAsString from "@/components/global/TimeSlots/TimeSlotAsString.vue";
import {mapGetters} from "vuex";

export default {
  name: "OrderDeliveryInfo",
  components: {
    TimeSlotAsString,
    DeliveryStatusIndicator,
    AddressDisplay,
    ButtonElement,
    FulfilmentNumberDisplay,
    BoxQuantity
  },
  computed: {
    ...mapGetters('order', [
      'getOrder',
      'getInvoiceAddress',
      'getDeliveryAddress',
      'getCustomerGeneralDetails',
      'getDeliveryTimeSlot',
      'getDeliveryInstructions',
    ]),
  }
}
</script>