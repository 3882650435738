<template>
    <div>
        <touch-optimized-button
                @click="$refs.modal.open()"
        >
            <span v-if="getPickupFilter" class="font-bold">{{ getPickupFilter.label }}</span>
            <span v-else>Pickup</span>
        </touch-optimized-button>

        <touch-optimized-modal ref="modal">
            <div class="flex flex-wrap gap-8 clear-both">
                <touch-optimized-modal-button
                        v-for="option in possibleOptions"
                        :key="option.value"
                        :border-class="`border-${option.color}`"
                        :text-class="`text-${option.color}`"
                        @click="onClickStatus(option)"
                >
                    {{ option.label }}
                </touch-optimized-modal-button>
            </div>
        </touch-optimized-modal>
    </div>
</template>

<script>
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import TouchOptimizedModal from "@/components/touchOptimized/Modal/TouchOptimizedModal";
import TouchOptimizedModalButton from "@/components/touchOptimized/Modal/TouchOptimizedModalButton";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "OrderFulfilmentPickupButton",
    components: {TouchOptimizedModalButton, TouchOptimizedModal, TouchOptimizedButton},
    data: () => ({
        possibleOptions: [
            {label: 'Avec Pickup', value: 'true', color: 'yellow-300'},
            {label: 'Sans Pickup', value: 'false', color: 'yellow-600'},
        ]
    }),
    mounted() {
        if (this.getPickupFilter === null ||  this.getPickupFilter === undefined ) {
            this.setPickupFilter({label: 'Sans Pickup', value: false, color: 'yellow-600'});
        }
    },
    methods: {
        ...mapMutations('fulfilmentFilters', [
            'setPickupFilter'
        ]),
        onClickStatus(value) {
            this.setPickupFilter(value);
            this.$refs.modal.close();
        },
    },
    computed: {
        ...mapGetters('fulfilmentFilters', [
            'getPickupFilter'
        ]),
    },
}
</script>

