<template>
  <div>
    <div class="flex gap-x-4 items-center">
      <component :is="dynamicLogo" v-if="dynamicLogo" />
      <div>
        <h3 class="text-xl font-bold">Enlèvement</h3>
        <span class="italic text-sm">Réf.: {{ getOrderPickup.externalReference }}</span>
        <pickup-status-badge class="mt-1" :status="getOrderPickup.status" small />
      </div>
    </div>

    <div class="mt-2 border-b">
      <span class="font-medium text-lg text-luxcaddy-600"> {{ getConsumer.name }}</span>
    </div>

    {{ getConsumer.status }}

    <div class="mt-2">
      <span v-if="getConsumer.phone">
        {{ getConsumer.phone }}<br>
      </span>
      <div>
        <fa-icon icon="home" class="mr-2 "/>
        <span class="font-bold">Addresse:&nbsp;</span>
        <span v-html="getConsumer.address" />
      </div>
      <div v-if="getConsumer.comment">
        <fa-icon icon="comments" class="mr-2 "/>
        <span class="font-bold">Commentaire:&nbsp;</span>
        <span>{{ getConsumer.comment }}</span>
      </div>


      <div class="mt-4" v-if="getOrderPickup.pickedUpAt || getOrderPickup.pickupEta">
        <div v-if="getOrderPickup.pickedUpAt">
          <fa-icon icon="check" class="text-luxcaddy mr-2" />
          Enlèvé à {{ $date(getOrderPickup.pickedUpAt).format('DD.MM.YYYY HH:mm') }}
        </div>
        <div v-if="getOrderPickup.pickupEta">
          <fa-icon icon="hourglass-half" class="text-yellow-600 mr-2" />
          Enlèvement vers {{ $date(getOrderPickup.pickupEta).format('DD.MM.YYYY HH:mm') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PickupStatusBadge from "@/components/pages/Pickups/PickupStatus/PickupStatusBadge.vue";

export default {
  name: "OrderPickupInfo",
  components: {PickupStatusBadge},
  data() {
    return {
      dynamicLogo: null
    }
  },
  async mounted() {
    // Load the SVG Component by Provider.
    if(this.getProviderLogoComponent) {
      const componentPath = this.getProviderLogoComponent;
      const module = await import(`@/${componentPath}`);
      this.dynamicLogo = module.default;
    }
  },
  computed: {
    ...mapGetters('order', [
      'getOrderPickup'
    ]),
    getProviderConfigValues() {
      return this.$luxcaddyConfig("pickup.providers").find(provider => provider.type === this.getOrderPickup.type);
    },
    getProviderLogoComponent() {
      return this.getProviderConfigValues.logo;
    },
    getConsumer() {
      return this.getOrderPickup.consumer;
    },
  },
}
</script>