<template>
  <modal ref="modal" :show-close-button="true" title="Fermer jusq'à une certaine heure">
    <date-picker-element
      v-model="dateUntil"
      label="Date et heure"
      validation-rules="required"
      with-time
    />

    <template #footer>
      <div class="flex flex-wrap gap-4 justify-end">
        <button-element type="danger" @click="setWoltOffline">
          Fermer
        </button-element>

        <button-element type="light" @click="closeModal">
          Annuler
        </button-element>
      </div>
    </template>

  </modal>
</template>

<script>
import Modal from "@/components/elements/modals/Modal.vue";
import DatePickerElement from "@/components/elements/forms/elements/base/DatePickerElement.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import WoltRepository from "@/repositories/WoltRepository";

export default {
  name: "WoltCloseVenueUntilTimeModal",
  components: {ButtonElement, DatePickerElement, Modal},
  data() {
    return {
      dateUntil: null
    }
  },
  methods: {
    setWoltOffline() {
      WoltRepository.venues.setVenueOnline("OFFLINE", this.dateUntil).then(() => {
        this.dateUntil = null;
        this.$emit("change");
        this.closeModal();
      })
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  },
}
</script>