<template>
  <div>
    <div>
      <page-title>
        [Wolt] Ouverture
      </page-title>

      <card>
        <wolt-manage-venue-online-status-form />
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import WoltManageVenueOnlineStatusForm
  from "@/components/pages/Wolt/ManageOnlineStatus/WoltManageVenueOnlineStatusForm.vue";

export default {
  name: "WoltUpdateVenueOnlinePage",
  components: {WoltManageVenueOnlineStatusForm, PageTitle, Card},
}
</script>