<template>
  <div
    :class="widthClass"
    class="whitespace-nowrap border border-blue-800 rounded text-center text-blue-800"
  >
    <div class="flex flex-nowrap">
      <div
        v-if="isUnset"
        class="py-1 pl-2 pr-3 flex-1 text-left"
      >
        <fa-icon class="mr-1" fixed-width icon="truck"/>
        Sans livraison
      </div>
      <template v-else>
        <div class="py-1 pl-2 pr-3 flex-1 text-left">
          <fa-icon class="mr-1" fixed-width icon="truck"/>
          {{ getFrom.format('DD.MM.YY HH:mm') }}
          <fa-icon fixed-width icon="arrow-right" v-if="getTo"/>
          <span v-if="getTo" >{{ getFrom.isSame(getTo, 'day') ? getTo.format('HH:mm') : getTo.format('DD.MM.YY HH:mm') }} </span>
        </div>

        <div class="bg-blue-800 text-white flex-grow-0 w-8 py-1 font-medium">
          {{ zone }}
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TimeSlotAsString",
  props: {
    from: {
      type: [String, Object],
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    zone: {
      type: Number,
      default: null
    },
    widthClass: {
      type: String,
      default: "w-min"
    }
  },
  computed: {
    getFrom() {
      if (this.from instanceof dayjs) {
        return this.from;
      }
      return this.$date(this.from);
    },
    getTo() {
      if (this.to === null) {
          return null;
      }
      if (this.to instanceof dayjs) {
        return this.to;
      }
      return this.$date(this.to);
    },
    isUnset() {
      return this.from === null && this.to === null && this.zone === null;
    }
  },
}
</script>