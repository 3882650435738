import Vue from "vue";

const getDefaultState = () => {
  return {
    product: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getProductId: state => state.product?.id,
    getProductName: state => state.product?.name,
    getProductCommentsCount: state => state.product?.commentsCount,
    isProductPublished: state => state.product?.isPublished || false,
    isProductWoltPublished: state => state.product?.isWoltPublished || false,
    isForceShopVisibility: state => state.product?.isShopVisibilityForced,
    hasLoadedProduct: state => state.product !== null,
  },
  mutations: {
    setProduct(state, product) {
      Vue.set(state, 'product', product)
    }
  },
}