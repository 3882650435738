<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
      <touch-optimized-select
        v-model="selectedDepartment"
        :allow-unselecting="false"
        :limit-option-menu-width="false"
        :options="getMappedDepartments"
        label="Departement"
      />
      <touch-optimized-select
        v-model="selectedDay"
        :allow-unselecting="false"
        :limit-option-menu-width="false"
        :options="getNextDays"
        label="Date"
      />
        <touch-optimized-select
            v-model="selectedWithPickupFilter"
            :allow-unselecting="false"
            :limit-option-menu-width="false"
            :options="getWithPickupFilter"
            label="Pickup"
        />
    </div>

    <touch-optimized-button
      :disabled="selectedDepartment === null"
      type="secondary"
      @click="createNewGroup"
    >
      Commencer nouveau groupe
    </touch-optimized-button>
  </div>
</template>

<script>
import TouchOptimizedSelect from "@/components/touchOptimized/Form/TouchOptimizedSelect";
import DepartmentRepository from "@/repositories/DepartmentRepository";
import TouchOptimizedButton from "@/components/touchOptimized/TouchOptimizedButton";
import FulfilmentRepository from "@/repositories/FulfilmentRepository";
import OrderRepository from "@/repositories/OrderRepository";

export default {
  name: "PickingGroupListSelections",
  components: {TouchOptimizedButton, TouchOptimizedSelect},

  data: () => ({
    availableDepartments: [],
    availableWeekDays: [],
  }),
  mounted() {
    this.fetchDepartments();
    this.fetchNextDays();
    this.setInitialPickupFilter()
  },
  watch: {
      selectedWithPickupFilter:  function () {
          this.fetchNextDays();
      },
  },
  methods: {
    fetchDepartments() {
      DepartmentRepository.getAll().then((res) => {
        this.availableDepartments = res.data.data;
        this.setInitialDepartmentSelection();
      });
    },
    fetchNextDays() {
        OrderRepository.fulfilment.getNextWeekdaysWithOrderCount(this.selectedWithPickupFilter).then((res) => {
        this.availableWeekDays = res.data.data;
        this.$nextTick(() => {
          this.setInitialDaySelection();
        });
      });
    },

    createNewGroup() {
      FulfilmentRepository
        .createFulfilmentGroup(this.selectedDepartment, this.selectedDay, this.selectedWithPickupFilter)
        .then((res) => {
          this.$router.push(
            {
              name: 'touchInterface.packaging.picking.details',
              params: {
                group: res.data.data.id
              }
            }
          );
        });
    },

    /**
     * If Department previously set, change to null
     */
    setInitialDepartmentSelection() {
      if (this.availableDepartments.length) {
        const values = this.getMappedDepartments.flatMap(dep => dep.value);
        if (!values.includes(this.selectedDepartment)) {
          this.selectedDepartment = null;
        }
      }
    },
    /**
     * Set to First Day when previously selected day is not available for selection anymore.
     */
    setInitialDaySelection() {
      if (this.availableWeekDays.length) {
        const dayValues = this.getNextDays.flatMap(d => d.value);
        if (!dayValues.includes(this.selectedDay)) {
          this.selectedDay = this.getNextDays[0].value;
        }
      }
    },
    setInitialPickupFilter() {
        if (this.selectedWithPickupFilter === null) {
            this.selectedWithPickupFilter = 'false';

        }
    }
  },
  computed: {
    selectedDay: {
      get() {
        return this.$store.getters['fulfilmentFilters/getSelectedDay'];
      },
      set(value) {
        return this.$store.commit('fulfilmentFilters/setSelectedDay', value);
      }
    },
    selectedDepartment: {
      get() {
        return this.$store.getters['fulfilmentFilters/getSelectedDepartmentId'];
      },
      set(value) {
        let department = this.availableDepartments.filter(obj => {
          return obj.id === value
        })
        department = department ? department[0] : null;

        return this.$store.commit('fulfilmentFilters/setSelectedDepartment', department);
      }
    },
    selectedWithPickupFilter: {
      get() {
        return this.$store.getters['fulfilmentFilters/getPickupFilterValue'];
      },
      set(value) {
          let filter = this.getWithPickupFilter.filter(obj => {
              return obj.value === value
          })

          filter = filter ? filter[0] : {label: 'Sans Pickup', value: 'false'};

          return this.$store.commit('fulfilmentFilters/setPickupFilter', filter);
      }
    },

    getMappedDepartments() {
      return this.availableDepartments.map(d => {
        return {
          label: d.name,
          value: d.id
        }
      })
    },

    getWithPickupFilter() {
      return [
          {label: 'Avec Pickup', value: 'true'},
          {label: 'Sans Pickup', value: 'false'},
      ]
    },
    getNextDays() {
      return this.availableWeekDays.map(wd => {
        return {
          label: this.$date(wd.date).format('DD.MM.YY') + ` - ${wd.orders} commandes`,
          value: wd.date
        };
      });
    }
  },
}
</script>

