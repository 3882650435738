import {
  FILTER_BOOLEAN,
  FILTER_PRODUCT_CATEGORY,
   FILTER_PRODUCT_SUPPLIER_MULTI,
  FILTER_PRODUCT_TAG_MULTI,
  FILTER_SELECT_SINGLE
} from "@/components/listTable/includes/controls/filters/availableFilters";

export let productFilters = [
  {
    caption: 'ID(s) ou Nom produit',
    filterKey: 'productIdsOrName',
    forceIsVisible: true,
    small: true
  },
  {
    caption: "Category",
    filterKey: "categories",
    type: FILTER_PRODUCT_CATEGORY,
    forceIsVisible: true,
  },
  {
    caption: "Fournisseur",
    filterKey: "supplierIds",
    type: FILTER_PRODUCT_SUPPLIER_MULTI,
    forceIsVisible: true,
    small: true,
  },
  {
    caption: "Tags",
    filterKey: "tags",
    type: FILTER_PRODUCT_TAG_MULTI,
    forceIsVisible: true,

  },
  {
    caption: 'Réf. fournisseur',
    filterKey: 'supplierReferences',
    forceIsVisible: true,
    small: true
  },
  {
    caption: "ID(s) ou Nom marque",
    filterKey: "brandIdsOrName",
    forceIsVisible: true,
    small: true,
  },
  {
    caption: 'Code barre',
    filterKey: 'barcode',
    forceIsVisible: true,
    small: true
  },
  {
    caption: 'Étagère',
    filterKey: 'stockAddress',
    forceIsVisible: true,
    small: true
  },
  {
    caption: 'Référence Valorlux',
    filterKey: 'valorluxReference',
  },
  {
    caption: 'Référence NC8',
    filterKey: 'nc8Reference',
  },
  {
    caption: 'Flux tendu',
    filterKey: 'isJustInTime',
    type: FILTER_BOOLEAN,
    forceIsVisible: true,
    small: true

  },
  {
    caption: 'Publié',
    filterKey: 'publishedStatus',
    type: FILTER_SELECT_SINGLE,
    forceIsVisible: true,
    filterOptions: [
      { label: 'Tous', value: null },
      { label: 'Publié', value: 'published' },
      { label: 'UnPubliée', value: "unpublished" },
      { label: 'UnPubliée + visibilité force', value: "unpublished_with_forced_visibility" },
      { label: 'UnPubliée + sauf visibilité force', value: "unpublished_without_forced_visibility" },
    ],
    small: true
  },
  {
    caption: 'Produits discontinués',
    filterKey: 'isDiscontinued',
    type: FILTER_BOOLEAN,
    defaultValue: 0,
    forceIsVisible: false,
    small: true
  },
  {
    caption: 'Publié wolt',
    filterKey: 'isWoltPublished',
    type: FILTER_BOOLEAN,
    forceIsVisible: false,
    small: true
  },
  {
    caption: 'Traductions des descriptions manquants',
    filterKey: 'isMissingDescription',
    type: FILTER_BOOLEAN,
    defaultValue: null,
    forceIsVisible: false,
    small: true
  },
  {
    caption: 'Traductions d\'ingrédients manquants',
    filterKey: 'isMissingLocalizedIngredients',
    type: FILTER_BOOLEAN,
    small: true
  },
  {
    caption: 'Saisonal',
    filterKey: 'seasonal',
    type: FILTER_SELECT_SINGLE,
    defaultValue: null,
    filterOptions: [
      {label: 'Tous', value: null},
      {label: 'Publiée + Pas en Saison', value: "active_not_in_season"},
      {label: 'Non publiée + en Saison', value: "not_active_in_season"},
    ]
  },
];