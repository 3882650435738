<template>
  <div class="p-4 mx-auto">
    <div class="p-3 bg-gray-100 border border-gray-300 rounded-md">
      <picking-group-list-selections/>
    </div>
    <div class="p-3 mt-4 bg-gray-100 border border-gray-300 rounded-md text-2xl">
      En attente:
      <span class="font-bold">{{ unAttributedOrderCount }}</span> commandes
      |
      <span class="font-bold">{{ unAttributedItemsSum }}</span> produits<br>
    </div>

    <div
      v-for="group in groups"
      :key="group.id"
      class="mt-4 grid grid-cols-1 gap-4"
    >
      <picking-group-list-item
        :group="group"
      />
    </div>
  </div>
</template>

<script>
import PickingGroupListSelections
  from "@/components/pages/TouchInterface/Packaging/Picking/List/Includes/PickingGroupListSelections";
import {mapGetters} from "vuex";
import FulfilmentRepository from "@/repositories/FulfilmentRepository";
import PickingGroupListItem
  from "@/components/pages/TouchInterface/Packaging/Picking/List/Includes/PickingGroupListItem";

export default {
  name: "PickingGroupList",
  components: {PickingGroupListItem, PickingGroupListSelections},
  data: () => ({
    groups: [],
    unAttributedItemsSum: 0,
    unAttributedOrderCount: 0,
  }),
  watch: {
    getSelectedDay(val) {
      if (!val)
        return;

      this.fetchGroups();
    },
    getSelectedDepartmentId(val) {
      if (!val)
        return;

      this.fetchGroups();
    },
    getPickupFilterValue(val) {
      if (val === null )
        return;

      if (!this.getSelectedDepartmentId ) {
          return;
      }
      this.fetchGroups();
    }
  },
  mounted() {
    this.fetchGroups()
  },
  computed: {
    ...mapGetters('fulfilmentFilters', ['getSelectedDay', 'getSelectedDepartmentId', 'getPickupFilterValue'])
  },
  methods: {
    fetchGroups() {
      if (this.getSelectedDepartmentId === null) {
        return false;
      }

      FulfilmentRepository
        .getFulfilmentGroupsByDateAndDepartment(this.getSelectedDepartmentId, this.getSelectedDay, this.getPickupFilterValue)
        .then((res) => {
          this.groups = res.data.data.groups;
          this.unAttributedOrderCount = res.data.data.unAttributedOrderCount;
          this.unAttributedItemsSum = res.data.data.unAttributedItemsSum;
        });
    },
  },
}
</script>

