<template>
  <div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">

      <div class="flex flex-col gap-3 flex-wrap">
        <order-info-card
          class="w-full flex-grow"
          icon="calendar"
        >
          <div class="h-full | flex flex-col justify-center">
            {{ $date(getOrder.createdAt).format('DD.MM.YY HH:mm') }}
          </div>
        </order-info-card>

        <order-info-card
          class="w-full flex-grow"
          icon="user"
        >
          <div class="h-full | flex flex-col justify-center">
            <div class="flex flex-wrap items-center gap-x-3">
              <member-quick-action-modal :id="getOrder.memberId"/>
              <div>{{ getInvoiceAddress.fullName }}</div>
            </div>
          </div>
        </order-info-card>

        <order-info-card
          class="w-full flex-grow"
          icon="file-invoice"
        >
          <div class="h-full | flex flex-col justify-center">
            <div class="flex flex-nowrap">
              <address-display :address="getInvoiceAddress" :show-map-button="false"/>
            </div>
          </div>
        </order-info-card>
      </div>

      <order-info-card
        :icon="getOrderInfoCardIcon"
      >

        <order-delivery-info
          v-if="getOrder.delivery"
        />
        <order-pickup-info
          v-else-if="getOrder.pickup"
        />
        <div
          v-else
        >
          <div class="italic">
            Cette commande n'est pas associée à une livraison / un pick-up.
          </div>
        </div>

      </order-info-card>

      <edit-order-settings class="lg:col-span-2"></edit-order-settings>
    </div>
  </div>
</template>

<script>
import AddressDisplay from "../../../../elements/display/address/AddressDisplay";
import EditOrderSettings from "../Settings/EditOrderSettings";
import {mapGetters} from "vuex";
import OrderInfoCard from "@/components/elements/display/order/OrderInfoCard";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import OrderDeliveryInfo from "@/components/pages/Orders/EditOrder/Info/Sub/OrderDeliveryInfo.vue";
import OrderPickupInfo from "@/components/pages/Orders/EditOrder/Info/Sub/OrderPickupInfo.vue";

export default {
  name: "OrderInformation",
  components: {
    OrderPickupInfo,
    OrderDeliveryInfo,
    MemberQuickActionModal,
    OrderInfoCard,
    EditOrderSettings,
    AddressDisplay,
  },
  computed: {
    ...mapGetters('order', [
      'getOrder',
      'getInvoiceAddress',
      'getDeliveryAddress',
      'getCustomerGeneralDetails',
      'getDeliveryTimeSlot',
      'getDeliveryInstructions',
    ]),
    getOrderInfoCardIcon() {
      if(this.getOrder.pickup) {
        return "box";
      }

      return "truck";
    }
  }
}
</script>

